import HomePageV2, { getServerSideProps as getServerSidePropsHandler } from 'pages/homev2';
import { routes } from 'src/constants/routes';

const pageId = routes.home.page;

export const getServerSideProps = getServerSidePropsHandler;

HomePageV2.pageId = pageId;
HomePageV2.pagePathView = '/';

export default HomePageV2;
